import React, { useState } from "react"
import PropTypes from "prop-types"
import { graphql } from "gatsby"

import {
  Banner,
  CategoryButtons,
  ContentWrapper,
  QuestionAccordion,
} from "../components"

const FaqPageTemplate = ({ data }) => {
  const { title, subtitle, categories, banner } =
    data.markdownRemark.frontmatter

  const [selectedCategory, setSelectedCategory] = useState(categories[0].title)

  const filteredCategories =
    selectedCategory === ""
      ? categories
      : categories.filter(item => item.title === selectedCategory)

  return (
    <>
      <ContentWrapper title={title} subtitle={subtitle}>
        <CategoryButtons
          showOnMobile
          categories={categories}
          allowUnselectCategory={false}
          selectedCategory={selectedCategory}
          setSelectedCategory={setSelectedCategory}
        />
        <QuestionAccordion questions={filteredCategories[0].questions} />
      </ContentWrapper>
      <Banner data={banner} />
    </>
  )
}

FaqPageTemplate.propTypes = {
  data: PropTypes.object.isRequired,
}

export default FaqPageTemplate

export const pageFragment = graphql`
  fragment FaqPage on MarkdownRemarkFrontmatter {
    title
    subtitle
    categories {
      title
      questions {
        title
        text
      }
    }
    banner {
      title
      subtitle
      button {
        title
        link
      }
    }
  }
`
