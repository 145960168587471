import React from "react"
import { graphql } from "gatsby"
import Layout from "../../layouts/fr"
import FaqPageTemplate from "../../templates/faq-page"

const FaqPageFrench = props => {
  const { title, subtitle } = props.data.markdownRemark.frontmatter

  return (
    <Layout location={props.location} title={title} description={subtitle}>
      <FaqPageTemplate {...props} />
    </Layout>
  )
}
export default FaqPageFrench

export const pageQuery = graphql`
  query FaqPageFrench {
    markdownRemark(
      fileAbsolutePath: { regex: "/static-pages/faq/index.fr.md/" }
    ) {
      frontmatter {
        ...FaqPage
      }
    }
  }
`
